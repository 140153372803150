import { history, hotel as gnHotel } from "gn-shared";
import { YieldApi } from "../../../api/yield";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  FilterList,
  FirstPage,
  LastPage,
  SaveAlt,
  Search,
} from "@mui/icons-material";
import moment from "moment";
import React, { forwardRef } from "react";
import Modal from "react-modal";
import MaterialTable, { Icons } from "material-table";
import { connect } from "react-redux";

const tableIcons: Icons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
};

const style = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderColor: "rgba(255, 0, 0, 1)",
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 150,
  },
};

const eventlookup: any = {};
eventlookup[history.events.closeRewardsEvent] = "close rewards";
eventlookup[history.events.openRewardsEvent] = "open rewards";
eventlookup[history.events.editedBaseRate] = "base rate";
eventlookup[history.events.editedAvailibilities] = "update availability";
eventlookup[history.events.editedRestriction] = "update restriction";
eventlookup[history.events.publishedAvailibilities] = "publish availability";
eventlookup[history.events.publishedRate] = "publish rates";
eventlookup[history.events.publishedRestriction] = "publish restrictions";
eventlookup[history.events.initial] = "initial import ";
eventlookup[history.events.suggestedBaseRate] = "suggested rate";
eventlookup[history.events.leftToSell] = "left to sell";
eventlookup[history.events.editedManualRates] = "manual rate";
eventlookup[history.events.openSale] = "open sale";
eventlookup[history.events.closeSale] = "close sale";
eventlookup[history.events.openDay] = "open day";
eventlookup[history.events.closeDay] = "close day";
eventlookup[history.events.addLos2ForDay] = "apply minlos 2";
eventlookup[history.events.rmLos2ForDay] = "remove minlos 2";
eventlookup[history.events.refreshAvailibiltyEvent] = "CM refresh availability";

export interface HistoryData {
  timestamp: number;
  user: string;
  event: string;
  value: string;
  target: {
    rt: string;
    rp?: string;
  };
}

interface State {
  isOpen: boolean;
  dayDate: string;
  history: any;
}

class HistoryModal extends React.Component<any, State> {
  hotel: any;
  state: State = {
    isOpen: true,
    dayDate: "",
    history: {},
  };

  constructor(props: any) {
    super(props);
    this.state.isOpen = true;
    this.hotel = props.activeHotel;
  }

  componentDidMount() {}

  async open(dayDate: string) {
    let res = await YieldApi.getHistory({
      hotelId: this.hotel.hotelId,
      dayDate: dayDate,
    });
    let h = JSON.parse(res.Payload);

    this.setState({
      isOpen: true,
      dayDate: dayDate,
      history: h,
    });
  }

  close() {
    this.setState({ isOpen: false });
  }

  render() {
    let historyData = history.getHistorySumup(
      { history: this.state.history },
      50,
    );
    return (
      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={() => {
          this.setState({ isOpen: false });
        }}
        contentLabel="History Modal"
        style={style}
        ariaHideApp={false}
      >
        {" "}
        <React.Fragment>
          <div>
            <MaterialTable
              columns={getConf(this.hotel)}
              data={historyData}
              options={{
                search: false,
                exportButton: true,
              }}
              title={"changelog for " + this.state.dayDate}
              icons={tableIcons}
            />
          </div>
          <br />
          <button
            className="modalClose"
            onClick={() => {
              this.setState({ isOpen: false });
            }}
          >
            close
          </button>
        </React.Fragment>
      </Modal>
    );
  }
}

function getConf(hotel: any) {
  let hotelObject = JSON.parse(JSON.stringify(hotel));
  let h = gnHotel(hotelObject);
  let hMappings: any = { rt: {}, rp: {} };

  h.getRoomTypes().forEach((rt: any) => {
    hMappings.rt[rt.id] = rt.shortDescription;
  });
  h.getRatePlans().forEach((rp: any) => {
    hMappings.rp[rp.id] = rp.shortDescription;
  });
  hMappings.rt.all = "all";
  return [
    {
      title: "TimeStamp",
      field: "timestamp",
      render: (d: {
        timestamp:
          | string
          | number
          | void
          | moment.Moment
          | Date
          | (string | number)[]
          | moment.MomentInputObject
          | any;
      }) => moment(d.timestamp).format("DD-MM-YYYY h:mm"),
    },
    { title: "User", field: "user" },
    { title: "Type", field: "event", lookup: eventlookup },
    { title: "value", field: "value" },
    { title: "Room Type", field: "target.rt", lookup: hMappings.rt },
    { title: "ratePlan", field: "target.rp", lookup: hMappings.rp },
  ];
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
});

export default connect(mapStateToProps)(HistoryModal);
